import { ProductType } from './productTypes'

export const isUpgrade = (currentPackage, newPackage) => {
  return (currentPackage === ProductType.LIGHT && newPackage === ProductType.BASIC) ||
    (currentPackage === ProductType.LIGHT && newPackage === ProductType.EXPERT) ||
    (currentPackage === ProductType.BASIC && newPackage === ProductType.EXPERT)
}

export const isDowngrade = (currentPackage, newPackage) => {
  return (currentPackage === ProductType.BASIC && newPackage === ProductType.LIGHT) ||
    (currentPackage === ProductType.EXPERT && newPackage === ProductType.BASIC) ||
    (currentPackage === ProductType.EXPERT && newPackage === ProductType.LIGHT)
}

/**
 * @type {Object.<string, Product[]>}
 * @property {Product[]} BOTTIMMO
 * @property {Product[]} MARKERO
 *
 * @typedef {Object} Product
 * @property {string} productName
 * @property {Object} priceInfo
 * @property {Object} priceInfo.yearly
 * @property {Object} priceInfo.yearly.price
 * @property {number} priceInfo.yearly.price.CH
 * @property {number} priceInfo.yearly.price.default
 * @property {string} priceInfo.yearly.label
 * @property {Object} priceInfo.monthly
 * @property {Object} priceInfo.monthly.price
 * @property {number} priceInfo.monthly.price.CH
 * @property {number} priceInfo.monthly.price.default
 * @property {string} priceInfo.monthly.label
 * @property {string[]} highlightText
 * @property {string[]} features
 * @property {boolean} expandAllFeatures
 * @property {Object} [ribbon]
 * @property {string} ribbon.monthly
 * @property {string} ribbon.yearly
 * @property {string} buttonText
 * @property {boolean} [skipPaymentInformation]
 */

export const products = {
  bottimmo: [
    {
      productName: ProductType.LIGHT,
      priceInfo: {
        yearly: {
          price: {
            CH: 49.00,
            default: 39.00
          },
          label: 'products.light.price-info.yearly'
        },
        monthly: {
          price: {
            CH: 59.00,
            default: 49.00
          },
          label: 'products.light.price-info.monthly'
        }
      },
      highlightText: [
        'products.light.highlight-text.0',
        'products.light.highlight-text.1',
        'products.light.highlight-text.2'
      ],
      features: [
        'products.light.features.0',
        'products.light.features.1',
        'products.light.features.2',
        'products.light.features.3',
        'products.light.features.4',
        'products.light.features.5',
        'products.light.features.6',
        'products.light.features.7',
        'products.light.features.8'
      ],
      expandAllFeatures: false,
      ribbon: {
        monthly: 'products.ribbon.monthly'
      },
      isBookable: true,
      buttonText: 'products.light.button-text'
    },
    {
      productName: ProductType.BASIC,
      priceInfo: {
        yearly: {
          price: {
            CH: 249.00,
            default: 199.00
          },
          label: 'products.basic.price-info.yearly'
        },
        monthly: {
          price: {
            CH: 299.00,
            default: 249.00
          },
          label: 'products.basic.price-info.monthly'
        }
      },
      highlightText: [
        'products.basic.highlight-text.0',
        'products.basic.highlight-text.1',
        'products.basic.highlight-text.2'
      ],
      features: [
        'products.basic.features.0',
        'products.basic.features.1',
        'products.basic.features.2',
        'products.basic.features.3',
        'products.basic.features.4',
        'products.basic.features.5',
        'products.basic.features.6',
        'products.basic.features.7',
        'products.basic.features.8',
        'products.basic.features.9'
      ],
      expandAllFeatures: false,

      buttonText: 'products.basic.button-text'

    },
    {
      productName: ProductType.EXPERT,
      priceInfo: {
        yearly: {
          price: {
            CH: 599.00,
            default: 499.00
          },
          label: 'products.expert.price-info.yearly'
        },
        monthly: {
          price: {
            CH: 699.00,
            default: 599.00
          },
          label: 'products.expert.price-info.monthly'
        }
      },
      highlightText: [
        'products.expert.highlight-text.0',
        'products.expert.highlight-text.1',
        'products.expert.highlight-text.2'
      ],
      features: [
        'products.expert.features.0',
        'products.expert.features.1',
        'products.expert.features.2',
        'products.expert.features.3',
        'products.expert.features.4',
        'products.expert.features.5',
        'products.expert.features.6',
        'products.expert.features.7',
        'products.expert.features.8',
        'products.expert.features.9'
      ],
      expandAllFeatures: false,
      buttonText: 'products.expert.button-text',
      ribbon: {
        yearly: 'products.ribbon.yearly'
      }
    }
  ],
  gloim: [
    {
      productName: ProductType.PIONEER,
      priceInfo: {
        yearly: {
          price: {
            default: 300
          },
          label: 'products.gloim.pioneer.price-info.yearly'
        },
        monthly: {
          price: {
            default: 3000
          },
          label: 'products.gloim.pioneer.price-info.monthly'
        }
      },
      highlightText: [
        'products.gloim.pioneer.highlight-text.0',
        'products.gloim.pioneer.highlight-text.1',
        'products.gloim.pioneer.highlight-text.2'
      ],
      features: [
        'products.gloim.pioneer.features.0',
        'products.gloim.pioneer.features.1',
        'products.gloim.pioneer.features.2',
        'products.gloim.pioneer.features.3',
        'products.gloim.pioneer.features.4',
        'products.gloim.pioneer.features.5',
        'products.gloim.pioneer.features.6',
        'products.gloim.pioneer.features.7',
        'products.gloim.pioneer.features.8'
      ],
      expandAllFeatures: false,
      ribbon: {
        monthly: 'products.gloim.ribbon.monthly'
      },
      buttonText: 'products.gloim.pioneer.button-text',
      isBookable: true
    },
    {
      productName: ProductType.EXPERT,
      priceInfo: {
        yearly: {
          price: {
            default: 500
          },
          label: 'products.gloim.expert.price-info.yearly'
        },
        monthly: {
          price: {
            default: 5000
          },
          label: 'products.gloim.expert.price-info.monthly'
        }
      },
      highlightText: [
        'products.gloim.expert.highlight-text.0',
        'products.gloim.expert.highlight-text.1',
        'products.gloim.expert.highlight-text.2'
      ],
      features: [
        'products.gloim.expert.features.0',
        'products.gloim.expert.features.1',
        'products.gloim.expert.features.2',
        'products.gloim.expert.features.3',
        'products.gloim.expert.features.4',
        'products.gloim.expert.features.5',
        'products.gloim.expert.features.6',
        'products.gloim.expert.features.7',
        'products.gloim.expert.features.8',
        'products.gloim.expert.features.9'
      ],
      expandAllFeatures: false,
      buttonText: 'products.gloim.expert.button-text',
      ribbon: {
        yearly: 'products.gloim.ribbon.yearly'
      },
      isBookable: true
    }
  ],
  markero: [
    {
      productName: ProductType.LIGHT,
      skipPaymentInformation: true,
      priceInfo: {
        yearly: {
          price: {
            CH: 49.00,
            default: 39.00
          },
          label: 'products.light.price-info.yearly'
        },
        monthly: {
          price: {
            CH: 59.00,
            default: 49.00
          },
          label: 'products.light.price-info.monthly'
        }
      },
      highlightText: [
        'products.light.highlight-text.0',
        'products.light.highlight-text.1',
        'products.light.highlight-text.2'
      ],
      features: [
        'products.light.features.0',
        'products.light.features.1',
        'products.light.features.2',
        'products.light.features.3',
        'products.light.features.4',
        'products.light.features.5',
        'products.light.features.6',
        'products.light.features.7',
        'products.light.features.8'
      ],
      expandAllFeatures: false,
      ribbon: {
        monthly: 'products.ribbon.monthly'
      },
      buttonText: 'products.light.button-text'
    },
    {
      productName: ProductType.BASIC,
      priceInfo: {
        yearly: {
          price: {
            CH: 249.00,
            default: 199.00
          },
          label: 'products.basic.price-info.yearly'
        },
        monthly: {
          price: {
            CH: 299.00,
            default: 249.00
          },
          label: 'products.basic.price-info.monthly'
        }
      },
      highlightText: [
        'products.basic.highlight-text.0',
        'products.basic.highlight-text.1',
        'products.basic.highlight-text.2'
      ],
      features: [
        'products.basic.features.0',
        'products.basic.features.1',
        'products.basic.features.2',
        'products.basic.features.3',
        'products.basic.features.4',
        'products.basic.features.5',
        'products.basic.features.6',
        'products.basic.features.7',
        'products.basic.features.8',
        'products.basic.features.9'
      ],
      expandAllFeatures: false,

      buttonText: 'products.basic.button-text'

    },
    {
      productName: ProductType.EXPERT,
      priceInfo: {
        yearly: {
          price: {
            CH: 599.00,
            default: 499.00
          },
          label: 'products.expert.price-info.yearly'
        },
        monthly: {
          price: {
            CH: 699.00,
            default: 599.00
          },
          label: 'products.expert.price-info.monthly'
        }
      },
      highlightText: [
        'products.expert.highlight-text.0',
        'products.expert.highlight-text.1',
        'products.expert.highlight-text.2'
      ],
      features: [
        'products.expert.features.0',
        'products.expert.features.1',
        'products.expert.features.2',
        'products.expert.features.3',
        'products.expert.features.4',
        'products.expert.features.5',
        'products.expert.features.6',
        'products.expert.features.7',
        'products.expert.features.8',
        'products.expert.features.9'
      ],
      expandAllFeatures: false,
      buttonText: 'products.expert.button-text',
      ribbon: {
        yearly: 'products.ribbon.yearly'
      }
    }
  ]
}
